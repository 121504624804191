import { SleepDiaryForm } from '@component/sleep-diary/sleep-diary.component';
import { SEO } from '@components/legacy/mol.seo/seo.component';
import { SleepDiaryFormData } from '@global/utils/domain/sleep-diary';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { PageProps } from '@lp-src/utils/local-types';
import { appPaths } from '@lp-src/utils/path';
import { Body, Col, Grid, H1, Row } from '@web/atomic';
import { FormData } from '@web/atomic/legacy/obj.form';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useState } from 'react';

const SampleSleepDiaryPage: React.FunctionComponent<PageProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(async (formData: FormData<SleepDiaryFormData>): Promise<void> => {
    if (Object.keys(formData.error).length !== 0) return;
    console.log('submit: ', formData);
    setLoading(true);
    setTimeout(() => {
      navigate(appPaths.reportSample.path);
    }, 2000);
  }, []);

  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Diário do sono',
          description: 'Ferramenta para acompanhamento do sono',
        }}
      />

      <Grid>
        <Row mt mb center="xs">
          <Col xs={12} sm={12} md={7} lg={7}>
            <H1> Diário do sono</H1>
            <Body>
              Veja abaixo como é o nosso diário do sono na prática. <br />
              Lembre-se: Os valores podem ser aproximados, não se preocupe em ser preciso. Uma estimativa boa o suficiente é o que
              precisamos aqui.
            </Body>
          </Col>
        </Row>
        <Row mb center="xs">
          <Col xs={12} sm={12} md={7} lg={7}>
            <SleepDiaryForm onSubmit={handleSubmit} submitData={null} submitLoading={loading} />
          </Col>
        </Row>
      </Grid>
    </IndexLayout>
  );
};

export default SampleSleepDiaryPage;

export const query = graphql`
  query SampleSleepDiaryPage {
    site {
      ...SiteUrl
    }
  }
`;
